import React from "react";
import { api, appEnvironmentURL, routeUrlAuthenticated } from "../../services/api";

import { Button } from "@material-ui/core";
import { useIsMobile } from "../../hooks/useIsMobile";
import { Alert, Box } from "../../components";
import { handlingErrors } from "../../utils";

export const AccessPortalComponent = (props) => {
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const { cnpj } = props;
  const isMobile = useIsMobile();
  

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const handleOpenDialog = async () => {
    await api
      .get(`${routeUrlAuthenticated}/${cnpj}`)
      .then((response) => {
        const { url_authenticated } = response.data;

        window.open(`${appEnvironmentURL}${url_authenticated}`);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  return (
    <Box display="flex" justifyContent={isMobile ? "flex-start" : "center"}>
      <Button color="primary" onClick={handleOpenDialog}>
        Acessar Portal
      </Button>
      <Alert
        component="temporary"
        open={isAlert.open}
        severity={isAlert.status}
        message={isAlert.message}
        onClose={handleCloseSnackbar}
      />
    </Box>
  );
};
