import React from "react";
import { Paper } from "@material-ui/core";
import { Box } from "..";
import { useStyles } from "./style";

export const PaperComponent = (props) => {
  const classes = useStyles({ scroll: props.scroll });
  return (
    <Box
      component={Paper}
      elevation={props.elevation ? props.elevation : 1}
      p={props.p || 2}
      className={classes.paper}
      {...props}
    >
      {props.children}
    </Box>
  );
};
