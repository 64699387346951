import React from "react";
import { useLocation, useHistory, Link } from "react-router-dom";
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@material-ui/core";
import { Box, Icon, Typography, Divider } from "../../components";
import { logout } from "../../services/auth";
import { captalizeString } from "../../utils/index";

import { useStyles } from "./style";

export const DrawerComponent = (props) => {
  const history = useHistory();
  const classes = useStyles();
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const name = sessionStorage.getItem("name");
  const accessType = sessionStorage.getItem("access_type");

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
      variant={props.variant}
      open={props.open}
      onClose={props.onClose ? props.onClose : null}
    >
      <div className={classes.offset}></div>
      <Box display="flex" flexDirection="column" pl={2}>
        <Box display="flex" justifyContent="initial" mb={1}>
          <Typography
            component="span"
            variant="body1"
            className={classes.typography}
          >
            Olá, <strong>{captalizeString(name)}</strong>
          </Typography>
        </Box>
      </Box>
      <Divider />
      <List component="nav" className={classes.navbar}>
        {/* <ListItem
          button
          component={Link}
          to="/home"
          className={`${classes.listItem} ${
            splitLocation[1].includes("home") ? classes.active : ""
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <Icon name="home" color="primary" />
          </ListItemIcon>
          <ListItemText>Início</ListItemText>
        </ListItem> */}
        <ListItem
          button
          component={Link}
          to="/operations"
          className={`${classes.listItem} ${
            splitLocation[1].includes("operations") ? classes.active : ""
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <Icon name="operations" color="primary" />
          </ListItemIcon>
          <ListItemText>Operações</ListItemText>
        </ListItem>
        <ListItem
          button
          component={Link}
          to="/customers"
          className={`${classes.listItem} ${
            splitLocation[1].includes("customers") ? classes.active : ""
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <Icon name="customers" color="primary" />
          </ListItemIcon>
          <ListItemText>Clientes</ListItemText>
        </ListItem>
        {accessType !== null && accessType.includes("admin") ? (
          <ListItem
            button
            component={Link}
            to="/originator"
            className={`${classes.listItem} ${
              splitLocation[1].includes("originator") ? classes.active : ""
            }`}
          >
            <ListItemIcon className={classes.listIcon}>
              <Icon name="workgroup" color="primary" />
            </ListItemIcon>
            <ListItemText>Originadores</ListItemText>
          </ListItem>
        ) : (
          ""
        )}

        <ListItem
          button
          component={Link}
          to="/edit"
          className={`${classes.listItem} ${
            splitLocation[1].includes("edit") ? classes.active : ""
          }`}
        >
          <ListItemIcon className={classes.listIcon}>
            <Icon name="person" color="primary" />
          </ListItemIcon>
          <ListItemText>Editar perfil</ListItemText>
        </ListItem>
        <ListItem
          button
          onClick={() => logout(history)}
          component={Link}
          to="/auth"
          className={`${classes.listItem}`}
        >
          <ListItemIcon className={classes.listIcon}>
            <Icon name="logout" color="primary" />
          </ListItemIcon>
          <ListItemText>Sair</ListItemText>
        </ListItem>
      </List>
    </Drawer>
  );
};
