import { createPortal } from 'react-dom';
import { formatToPhone, formatToCPFOrCNPJ, formatToCNPJ } from "brazilian-values";

export const formatValue = (value, format) => {
  if (!value) return "-";

  const object = {
    cnpj: formatToCNPJ(value),
    cpf: formatToCPFOrCNPJ(value),
    captalize: captalizeString(value),
    telephone: formatToPhone(value),
  };

  return object[format] || value;
};

export const ParseDate = (date) =>
  date === null ? "00/00/0000" : date.toLocaleDateString();

export const captalizeString = (name) => {
  if(name === null || name === "") return name;
  const exceptions = ["e", "de", "da", "do", "das", "dos"]
  const words = name.match(/\b\w+\b/g);
  return words.map((e,i) => exceptions.indexOf(e) === -1 || i === 0 ? e[0].toUpperCase()+e.slice(1).toLowerCase() : e).join(" ");

};

export const formatDate = (value) => {
  
  return value.replace(/-/g, "/");
}

export const handlingSuccess = (response, setIsAlert) => {
  const { message, status } = response.data;
  setIsAlert((prevAlert) => ({
    ...prevAlert,
    open: true,
    message: message,
    status: status,
  }));
};

export const handlingErrors = (error, setIsAlert) => {
  if (error.response) {
    const { message, status } = error.response.data;
    setIsAlert((prevAlert) => ({
      ...prevAlert,
      open: true,
      message: message,
      status: status,
      url: "",
    }));
  } else if (error.request) {
    setIsAlert((prevAlert) => ({
      ...prevAlert,
      open: true,
      message: "A conexão com servidor falhou!",
      status: "error",
    }));
  } else {
    setIsAlert((prevAlert) => ({
      ...prevAlert,
      open: true,
      message: "Não foi possível carregar os dados!",
      status: "error",
    }));
  }
};

export function ReactPortal({ children, domNode }) {
  return createPortal(children, domNode);
}

export const stateOptions = [
  { name: "Acre", value: "AC" },
  { name: "Alagoas", value: "AL" },
  { name: "Amapá", value: "AP" },
  { name: "Amazonas", value: "AM" },
  { name: "Bahia", value: "BA" },
  { name: "Ceará", value: "CE" },
  { name: "Distrito Federal", value: "DF" },
  { name: "Espírito Santo", value: "ES" },
  { name: "Goiás", value: "GO" },
  { name: "Maranhão", value: "MA" },
  { name: "Mato Grosso", value: "MT" },
  { name: "Mato Grosso do Sul", value: "MS" },
  { name: "Minas Gerais", value: "MG" },
  { name: "Pará", value: "PA" },
  { name: "Paraíba", value: "PB" },
  { name: "Paraná", value: "PR" },
  { name: "Pernambuco", value: "PE" },
  { name: "Piauí", value: "PI" },
  { name: "Rio de Janeiro", value: "RJ" },
  { name: "Rio Grande do Norte", value: "RN" },
  { name: "Rio Grande do Sul", value: "RS" },
  { name: "Rondônia", value: "RO" },
  { name: "Roraima", value: "RR" },
  { name: "Santa Catarina", value: "SC" },
  { name: "São Paulo", value: "SP" },
  { name: "Sergipe", value: "SE" },
  { name: "Tocantins", value: "TO" },
  { name: "Estrageiro", value: "Estrangeiro" },
];

