import { Icon, Tooltip, Typography } from "../../../components";
import { formatToBRL } from "brazilian-values";
import { captalizeString } from "../../../utils";
import { AccessPortal } from "../../../layout";

const ChooseStatus = (status) => {
  const object = {
    passed: { 
      title: "O cliente avançou a etapa",
      color: "success",
      name: "check" 
    },
    awaiting: { 
      title: "O cliente se encontra nesta etapa",
      color: "warning",
      name: "time"
    },
    warning: { 
      title: "O cliente está aguardando a resposta da plataforma",
      color: "warningDark",
      name: "warning"
    },
    remove: { 
      title: "O cliente foi negado nesta etapa",
      color: "error",
      name: "remove"
    },
    default: {
      title: "O cliente não chegou nesta etapa"
    }
  }

  const { title, color, name } = object[status] || object.default;

  return (
    <Tooltip title={title} placement='top'>
      <Icon color={color} name={name} />
    </Tooltip>
  )
}

export const tableContractingColumns = [
  {
    name: "id",
    label: "ID",
  },
  {
    name: "corporate_name",
    label: "Estabalecimento comercial",
    options: {
      customBodyRender: (name) => {
        if (name) {
          return (
            <Typography variant="body2">{captalizeString(name)}</Typography>
          );
        }

        return <Typography variant="subtitle2">{name || "-"}</Typography>;
      },
    },
  },
  {
    name: "name",
    label: "Nome",
    options: {
      customBodyRender: (name) => {
        if (name) {
          return (
            <Typography variant="body2">{captalizeString(name)}</Typography>
          );
        }

        return <Typography variant="subtitle2">{name || "-"}</Typography>;
      },
    },
  },
  {
    name: "value",
    label: "Valor da operação",
    options: {
      sort: false,
      customBodyRender: (valor) => {
        return (
          <Typography variant="subtitle2">
            {formatToBRL(valor) || "-"}
          </Typography>
        );
      },
    },
  },
  {
    name: "progression",
    label: "Cadastro",
    options: {
      sort: false,
      customBodyRender: (value) => ChooseStatus(value.cadastro),
    },
  },
  {
    name: "progression",
    label: "Conexão fonte",
    options: {
      sort: false,
      customBodyRender: (value) => ChooseStatus(value.conexao_fonte),
    },
  },
  {
    name: "progression",
    label: "Análise",
    options: {
      sort: false,
      customBodyRender: (value) => ChooseStatus(value.analise),
    },
  },
  {
    name: "progression",
    label: "Ass. de contrato",
    options: {
      sort: false,
      customBodyRender: (value) => ChooseStatus(value.documentos),
    },
  },
  {
    name: "progression",
    label: "Desembolso",
    options: {
      sort: false,
      customBodyRender: (value) => ChooseStatus(value.desembolso),
    },
  },
  {
    name: "cnpj",
    label: " ",
    options: {
      sort: false,
      customBodyRender: (value) =>  <AccessPortal cnpj={value} />,
    },
  },
];
