import React from "react";
import { Helmet } from "react-helmet";
import { Alert, Box, Loading, Table } from "../../../components";
import { api, routeClients } from "../../../services/api";
import { handlingErrors, handlingSuccess } from "../../../utils";
import { tableClientColumns } from "./props";
import { GlobalContext } from "../../../context";
import { Empty } from "../../../layout";
import { debounceSearchRender } from "mui-datatables";

export const CustomersMainPageComponent = (props) => {
  const [page, setPage] = React.useState(0);
  const [count, setCount] = React.useState(1);
  const [data, setData] = React.useState([]);

  const [isLoading, setIsLoading] = React.useState(true);
  const [isLoadingAlert, setIsLoadingAlert] = React.useState(false);
  const global = React.useContext(GlobalContext);
  const [sortOrder, setSortOrder] = React.useState({
    name: "user_id",
    direction: "desc",
  });
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });

  const tableClientOptions = {
    download: false,
    print: false,
    filter: false,
    selectableRows: "none",
    rowsPerPageOptions: [10, 25, 50],
    responsive: "vertical",
    tableBodyMaxHeight: "",
    rowHover: false,
    serverSide: true,
    count: count,
    page: page,
    onTableChange: (action, tableState) => {
      switch (action) {
        case "changePage":
          setIsLoadingAlert(true);
          getData(tableState.page);
          setPage(tableState.page);
          break;
        case "changeRowsPerPage":
          setIsLoadingAlert(true);
          getData(tableState.page, tableState.rowsPerPage);
          setPage(tableState.page);
          break;
        case "search":
          setIsLoadingAlert(true);
          getData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText
          );
          setPage(tableState.page);
          break;
        case "sort":
          setIsLoadingAlert(true);
          getData(
            tableState.page,
            tableState.rowsPerPage,
            tableState.searchText,
            tableState.sortOrder.name,
            tableState.sortOrder.direction
          );
          setPage(tableState.page);
          break;
        default:
          return 0;
      }
    },
    customSearchRender: debounceSearchRender(1000),
    onColumnSortChange: (changedColumn, direction) => {
      let order = "desc";
      if (direction === "asc") {
        order = "asc";
      }
      setSortOrder((prevState) => ({
        ...prevState,
        name: changedColumn,
        direction: order,
      }));
    },
    sortOrder: sortOrder,
    textLabels: {
      body: {
        noMatch: "Desculpe, não encontramos nada",
        toolTip: "Ordenar",
      },
      selectedRows: {
        text: "linha(s) selecionada",
      },
      pagination: {
        next: "Próxima página",
        previous: "Página anterior",
        rowsPerPage: "Linhas por página:",
        displayRows: "de",
      },
      toolbar: {
        search: "Procurar",
        viewColumns: "Visualizar colunas",
        filterTable: "Filtrar tabela",
      },
      viewColumns: {
        title: "Mostrar colunas",
        titleAria: "Mostrar/Esconder colunas da tabela",
      },
    },
  };

  React.useEffect(() => {
    getData();
  }, [global.toggleAddCustomer]);

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const getData = (
    page = 0,
    limit = 10,
    search = null,
    columnSort = "user_id",
    columnDirection = "desc"
  ) => {
    api
      .get(
        `${routeClients}?page=${
          page + 1
        }&limit=${limit}&search=${search}&sort=${columnSort}&direction=${columnDirection}`
      )
      .then((response) => {
        setCount(response.data.total);

        if (response.data.clients.length === 0) {
          return handlingSuccess(response, setIsAlert);
        }

        const responseMap = response.data.clients.map((item) => {
          const participantControl = item.participant_control;
          const documentNumber = item.document_number;

          const url = documentNumber
            ? `/customers/details/${documentNumber}`
            : null;

          const viewDetails = {
            controll: participantControl,
            document_number: url,
            name: item.name,
            status: item.status,
          };

          const infoOriginator = {
            name: item.name,
            cnpj: item.document_number,
          };

          return (item = { ...item, viewDetails, infoOriginator });
        });
        setData(responseMap);
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      })
      .finally(() => {
        setIsLoading(false);
        setIsLoadingAlert(false);
      });
  };

  return isLoading ? (
    <Box display="flex" height="100vh">
      <Loading />
    </Box>
  ) : (
    <Box px={1} py={2} width="100%">
      <Helmet>
        <title>Clientes | Originador </title>
      </Helmet>
      {data.length === 0 ? (
        <Empty button />
      ) : (
        <Table
          data={data}
          title="Clientes"
          columns={tableClientColumns}
          options={tableClientOptions}
        />
      )}
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      {isLoadingAlert && <Alert component="loading" open={isLoadingAlert} />}
    </Box>
  );
};
