import axios from "axios";
import { getToken, logout } from "./auth";

//rotas api
export const routeLogin = "/login";
export const routeRegister = "/register";
export const routeForgotPassword = "/user/forgot-password";
export const routeResetPassword = "/user/password-reset";
export const routeExistsUser = "/exists-user";
export const routeClients = "/clients";
export const routeClientsCreate = "/clients/create";
export const routeUrlAuthenticated = "url-authenticated";
export const routeOperationsContracting = "/operations/contracting";
export const routeOperationsPorfolioChart = "/operations/portfolio/chart";
export const routeOperationsPortfolio = "/operations/portfolio";
export const routeOperationsPortfolioCCB = "/operations/ccb-file";
export const routeEditProfile = "/user/update/profile";
export const routeEditAddress = "/user/update/address";
export const routeEditPassword = "/user/update/password";
export const routeProfile = "/user/profile";
export const routeOriginators = "/administrative/get-registrations";
export const routeActionOriginators = "/administrative/change-status-originador";


const { REACT_APP_ENV } = process.env;

export let appEnvironmentURL = "https://tomatico.staging.captalys.io/";
export let environmentURL = "https://go.staging.captalysplatform.io/";

const getEnv = (env) => {
  switch (env) {
    case "production":
      environmentURL = "https://go.captalysplatform.io/";
      appEnvironmentURL = "https://app.tomatico.com/"
      return;
    case "staging":
      environmentURL = "https://go.staging.captalysplatform.io/";
      appEnvironmentURL = "https://tomatico.staging.captalys.io/"
      return;
    case "sandbox":
      environmentURL = "https://go.sandbox.captalysplatform.io/";
      appEnvironmentURL = "https://tomatico.sandbox.captalys.io/"
      return;
    case "development":
      environmentURL = "https://go.development.captalysplatform.io/";
      appEnvironmentURL = "https://tomatico.development.captalys.io/"
      return;
    default:
      environmentURL = "https://go.development.captalysplatform.io/"
      appEnvironmentURL = "https://tomatico.development.captalys.io/"
      return;
  }
}

getEnv(REACT_APP_ENV);

export const api = axios.create({
  baseURL: environmentURL,
});

api.interceptors.request.use(async (config) => {
  const token = getToken();
  const url = config.url;

  if (url.includes("viacep")) return config;

  if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  }

  return config;
});


api.interceptors.response.use((response) => {
  return response;

}, (error) => {
  const url = window.location.href;

  if (error.response.status === 401 && !url.includes("/auth")) {
    logout();
  }

  return Promise.reject(error);
});
