import React from "react";
import { AppBar, Container, Toolbar } from "@material-ui/core";
import { Link as MaterialLink } from "@material-ui/core";
import { Box, Typography } from "../../components";
import logo from "../../assets/icons/logo_go.svg";
import logoTomatico from "../../assets/images/logo-tomatico.svg";
import logoCaptalys from "../../assets/images/logo-captalys.svg";
import { useIsMobile } from "../../hooks/useIsMobile";

import { useStyles } from "./style";

export const FooterComponent = (props) => {
  const urlTomatico = "https://app.tomatico.com/minhaConta.html";
  const urlCaptalys = "https://www.captalys.com.br";
  const classes = useStyles({ drawerWidth: props.drawerWidth });
  const isMobile = useIsMobile();

  return (
    <AppBar position="static" className={classes.footer}>
      <Container maxWidth="lg">
        <Toolbar>
          <Box
            display="flex"
            justifyContent="space-between"
            my={2}
            flexDirection={isMobile ? "column" : "row"}
          >
            <Box
              display="flex"
              justifyContent={isMobile ? "center" : "flex-start"}
              width={isMobile ? "100%" : "15%"}
              alignSelf="flex-start"
            >
              <img
                width="100px"
                height="100px"
                src={logo}
                alt="Logo Grupos de Originadores"
              />
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={isMobile ? "center" : "flex-start"}
              width={isMobile ? "100%" : "65%"}
              my={isMobile ? 2 : 0}
            >
              <MaterialLink className={classes.link} href="/customers">
                Clientes
              </MaterialLink>
              <MaterialLink className={classes.link} href="/operations">
                Operações
              </MaterialLink>
              <MaterialLink className={classes.link} href="/editProfile">
                Meus dados
              </MaterialLink>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              alignItems={isMobile ? "center" : "flex-start"}
              alignSelf="flex-start"
              width={isMobile ? "100%" : "20%"}
            >
              <Typography>Acesse nossos sites:</Typography>
              <Box mt={2}>
                <MaterialLink
                  target="_blank"
                  href={urlCaptalys}
                  rel="noopener noreferrer"
                >
                  <img
                    width="100px"
                    height="50px"
                    src={logoCaptalys}
                    alt="Logo Captalys"
                  />
                </MaterialLink>
                <MaterialLink
                  target="_blank"
                  href={urlTomatico}
                  rel="noopener noreferrer"
                >
                  <img
                    width="100px"
                    height="50px"
                    src={logoTomatico}
                    alt="Logo tomático"
                  />
                </MaterialLink>
              </Box>
            </Box>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
