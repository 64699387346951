import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@material-ui/core";
import { useStyles } from "./style";

export const DialogComponent = (props) => {
  const classes = useStyles();
  return (
    <Dialog
      fullScreen={props.fullScreen}
      open={props.open}
      onClose={props.onClose}
      scroll={props.scroll}
      maxWidth={props.maxWidth}
    >
      {props.title && (
        <DialogTitle className={classes.title}>{props.title}</DialogTitle>
      )}
      <DialogContent>{props.children}</DialogContent>
      <DialogActions className={classes.actions}>
        <Button onClick={props.onClose} color="primary">
          {props.textCloseBtn || "Cancelar"}
        </Button>
        <Button
          onClick={props.onEvent}
          type={props.type || "button"}
          variant="contained"
          color="primary"
        >
          {props.textEventBtn || "Cadastrar"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
