import React from "react";
import { AppBar, Toolbar, IconButton } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import logo from "../../assets/icons/logo_go.svg";

import { useStyles } from "./style";

export const NavbarComponent = (props) => {
  const classes = useStyles({ drawerWidth: props.drawerWidth });
  const url = props.location.pathname;

  return (
    <>
      <AppBar className={classes.appBar} elevation={props.elevation}>
        <Toolbar className={classes.toolBar}>
          {url.includes("/auth") ? (
            ""
          ) : (
            <IconButton
              color="inherit"
              aria-label="menu"
              className={classes.menuButton}
              onClick={() => props.openDrawer()}
            >
              <MenuIcon />
            </IconButton>
          )}
          <img
            width="100px"
            height="40px"
            src={logo}
            alt="Logo Grupos de Originadores"
            className={classes.logo}
          />
        </Toolbar>
      </AppBar>
      <div className={classes.offset}></div>
    </>
  );
};
