import React from "react";
import {
  Switch,
  Route,
  Redirect,
  withRouter,
  useHistory,
} from "react-router-dom";
import {
  Hidden,
  useMediaQuery,
  Grid,
  CssBaseline,
  Container,
} from "@material-ui/core";
import { Navbar, Footer, Topbar, Drawer, Cookies } from "../layout";
import { useForm } from "react-hook-form";
import { useIsMobile } from "../hooks/useIsMobile";
import { Alert, Box, Dialog } from "../components";
import { Controls } from "../components/controls";
import { formOptions } from "./props";
import { AuthPage } from "./Auth";
import { CustomersPage } from "./Customers";
import { NotFoundPage } from "./NotFound";
import { OperationsPage } from "./Operations";
import { OriginatorPage } from "./Originator";
// import { HomePage } from "./Home";
import { useTheme } from "@material-ui/core/styles";
import { useStyles } from "./style";
import { PrivateRoute } from "../routes";
import { api, routeClientsCreate } from "../services/api";
import { handlingErrors, handlingSuccess } from "../utils";
import { GlobalContext } from "../context";
import { EditPage } from "./Edit";

export const PagesComponent = withRouter((props) => {
  const [open, setOpen] = React.useState(false);
  const [isAlert, setIsAlert] = React.useState({
    open: false,
    message: "",
    status: "",
  });
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const url = props.location.pathname;
  const isMobile = useIsMobile();
  const global = React.useContext(GlobalContext);
  const history = useHistory();

  const handleOpenDrawer = () => {
    setOpen(!open);
  };

  const handleOpenDialog = () => {
    global.setOpenDialogAddCustomer(true);
  };

  const handleCloseDialog = () => {
    global.setOpenDialogAddCustomer(false);
  };

  const { handleSubmit, control } = useForm(formOptions);

  const handleCloseSnackbar = () =>
    setIsAlert((prevAlert) => ({ ...prevAlert, open: false }));

  const onSubmit = async (data) => {
    const { cnpj, cpf, fullName, email, cellphone, password, confirmPassword } =
      data;

    await api
      .post(routeClientsCreate, {
        cnpj: cnpj,
        cpf: cpf,
        nome: fullName,
        email: email,
        celular: cellphone,
        senha: password,
        confirmaSenha: confirmPassword,
        produto: "tomatico",
      })
      .then((response) => {
        handlingSuccess(response, setIsAlert);
        global.setToggleAddCustomer((prev) => !prev);
        handleCloseDialog();
        history.push("/customers");
      })
      .catch((error) => {
        handlingErrors(error, setIsAlert);
      });
  };

  const classes = useStyles({
    marginTop: isMobile ? (url.includes("/auth") ? "32px" : "64px") : "64px",
    drawerWidth: url.includes("/auth") ? "0px" : "200px",
    marginLeft: url.includes("/auth") ? "auto" : "200px",
  });

  return (
    <Box className={classes.root}>
      {isAlert.open && (
        <Alert
          component="temporary"
          open={isAlert.open}
          severity={isAlert.status}
          message={isAlert.message}
          onClose={handleCloseSnackbar}
        />
      )}
      <CssBaseline />
      <Navbar
        drawerWidth={url.includes("/auth") ? "0px" : "200px"}
        elevation={url.includes("/auth") ? 4 : 0}
        openDrawer={handleOpenDrawer}
        {...props}
      />
      {url.includes("/auth") ? (
        ""
      ) : (
        <>
          <Hidden xsDown>
            <Drawer variant="permanent" open={true} url={url} />
          </Hidden>
          <Hidden smUp>
            <Drawer
              variant="temporary"
              open={open}
              onClose={handleOpenDrawer}
            />
          </Hidden>
        </>
      )}
      <Box component="main" className={classes.content}>
        <Container maxWidth="lg" className={classes.container}>
          <Grid container>
            {url.includes("/auth") ? (
              ""
            ) : (
              <Topbar
                handleClickTopbarButton={handleOpenDialog}
                drawerWidth={url.includes("/auth") ? "0px" : "200px"}
              />
            )}
            <Dialog
              fullScreen={fullScreen}
              open={global.openDialogAddCustomer}
              onClose={handleCloseDialog}
              scroll="body"
              title="Preencha as informações abaixo"
              maxWidth="xs"
              onEvent={handleSubmit(onSubmit)}
              type="submit"
            >
              <form className={classes.form} noValidate>
                <Controls.InputMask
                  name="cnpj"
                  control={control}
                  label="CNPJ"
                  placeholder="Digite o CNPJ do cliente"
                  fullWidth
                  required
                  mask="99.999.999/9999-99"
                />
                <Controls.InputMask
                  name="cpf"
                  control={control}
                  label="CPF"
                  placeholder="Digite o CPF do cliente"
                  fullWidth
                  required
                  mask="999.999.999-99"
                />
                <Controls.Input
                  name="fullName"
                  control={control}
                  label="Nome Completo"
                  placeholder="Digite o nome completo do cliente"
                  fullWidth
                  required
                />
                <Controls.Input
                  name="email"
                  control={control}
                  label="E-mail"
                  placeholder="Digite o e-mail do cliente"
                  fullWidth
                  required
                />
                <Controls.InputMask
                  name="cellphone"
                  control={control}
                  label="Celular"
                  placeholder="Digite o celular do cliente"
                  fullWidth
                  required
                  mask="(99) 99999-9999"
                />
                <Controls.Input
                  name="password"
                  control={control}
                  label="Senha"
                  placeholder="Digite a senha para este cliente"
                  type="password"
                  fullWidth
                  required
                  autoComplete="on"
                />
                <Controls.Input
                  name="confirmPassword"
                  control={control}
                  label="Confirma senha"
                  placeholder="Confirme a senha para este cliente"
                  type="password"
                  fullWidth
                  required
                  autoComplete="on"
                />
              </form>
            </Dialog>
            <Switch>
              <Redirect from="/" exact to="/auth" />
              <Route path="/auth" component={AuthPage} />
              {/* <Route path="/home" component={HomePage} /> */}
              <Redirect from="/edit" exact to="/edit/profile" />
              <PrivateRoute
                path="/edit/:tab"
                render={(props) => <EditPage mainRoute="edit" {...props} />}
              />
              <Redirect from="/operations" exact to="/operations/contracting" />
              <PrivateRoute
                path="/operations/:tab"
                render={(props) => (
                  <OperationsPage mainRoute="operations" {...props} />
                )}
              />
              <PrivateRoute path="/originator" component={OriginatorPage} permission="admin" />
              <PrivateRoute path="/customers" component={CustomersPage} />
              <PrivateRoute path="*" component={NotFoundPage} />
            </Switch>
          </Grid>
        </Container>
        <Cookies />
      </Box>
      {!url.includes("/auth") && <Footer drawerWidth={"200px"} />}
    </Box>
  );
});
